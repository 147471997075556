import { render, staticRenderFns } from "./DeleteConfirmModal.vue?vue&type=template&id=5afb7314&scoped=true&"
import script from "./DeleteConfirmModal.vue?vue&type=script&lang=js&"
export * from "./DeleteConfirmModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5afb7314",
  null
  
)

export default component.exports