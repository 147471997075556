<template>
  <CModal
    @update:show="closeModal"
    title="Delete"
    color="primary"
    :show.sync="showModal"
    centered
    @click.
  >
    Are you sure about delete?
  </CModal>
</template>

<script>
import { CModal } from "@coreui/vue/src";

export default {
  name: "DeleteConfirmModal",
  props: ["show"],
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    show: function (value) {
      this.showModal = value;
    },
  },
  methods: {
    closeModal(show, event, accept) {
      if (accept) {
        this.$emit("accept");
      } else {
        this.$emit("close");
      }
    },
  },
  components: {
    CModal,
  },
};
</script>

<style scoped></style>
